import { Labels } from '../utils/Labels';

class RegisterService {
  static getSteps(hasPayment: boolean): string[] {
    const allSteps = [
      Labels.t('wizard.participants'),
      Labels.t('wizard.billing'),
      Labels.t('wizard.payment'),
      Labels.t('wizard.summary'),
      Labels.t('wizard.confirmation'),
    ];
    const noPaymentSteps = [
      Labels.t('wizard.participants'),
      Labels.t('wizard.account'),
      Labels.t('wizard.summary'),
      Labels.t('wizard.confirmation'),
    ];

    return hasPayment ? allSteps : noPaymentSteps;
  }

  static getCurrentStep(viewIndex: number, hasPayment: boolean): number {
    if (viewIndex === 3) {
      return hasPayment ? 3 : 2;
    }
    return viewIndex;
  }

  static getNextRoute(viewIndex: number, eventId: string, eventHasPayment: boolean): string {
    // participants
    if (viewIndex === 0) {
      return `/events/${eventId}/billing`;
    }

    // data facturare
    if (viewIndex === 1) {
      if (eventHasPayment) {
        return `/events/${eventId}/payment`;
      } else {
        return `/events/${eventId}/summary`;
      }
    }
    return `/events/${eventId}/participants`;
  }
  static getBackRoute(viewIndex: number, eventId: string, eventHasPayment: boolean): string {
    if (viewIndex === 3) {
      if (eventHasPayment) {
        return `/events/${eventId}/payment`;
      } else {
        return `/events/${eventId}/billing`;
      }
    }
    return `/events/${eventId}/participants`;
  }
}

export { RegisterService };

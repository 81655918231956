import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as QueryString from 'query-string';
import { PaymentService } from '../services';
import { useSessionStore } from '../store';
import { OrderResult } from '../models';

type ComponentProps = {};

const defaultModel: OrderResult = {
  orderNumber: 0,
  amount: 0,
  date: '',
  hasRedirect: false,
  redirectUrl: '',
  hasError: false,
};

const Pay = (props: React.PropsWithChildren<ComponentProps>): React.ReactElement => {
  const { search } = useLocation();
  const params = QueryString.parse(search);
  const [hasError, setHasError] = useState(false);
  const [model, setModel] = useState<OrderResult>(defaultModel);
  const [hasPaymentError, setHasPaymentError] = useState(false);
  const [hasPaymentSuccess, setHasPaymentSuccess] = useState(false);
  const { payReferrer, setPayReferrer } = useSessionStore();

  useEffect(() => {
    const invoiceId = params['invoice-id'] as string;
    const orderId = params.orderId;

    if (invoiceId) {
      if (setPayReferrer) setPayReferrer(document.referrer);
      const loadInvoice = async (): Promise<void> => {
        try {
          const data = await PaymentService.requestInvoiceOrderNumber(invoiceId);
          window.location.href = data.redirectUrl;
        } catch (error) {
          setHasError(true);
        }
      };
      loadInvoice();
    } else if (orderId) {
      const checkPayment = async (): Promise<void> => {
        try {
          const data = await PaymentService.verifyPayment(orderId as string);
          if (data.hasError) setHasPaymentError(true);
          setModel(data);
          setHasPaymentSuccess(true);
        } catch (error) {
          setHasPaymentError(true);
        }
      };
      checkPayment();
    } else {
      // Send it back - wrong parameters
      window.history.back();
    }
  }, []);

  if (hasError) {
    return (
      <div className="container">
        <div className="columns box-center-items">
          <div
            className="column is-two-thirds box-center has-text-centered"
            style={{ marginTop: '2rem', backgroundColor: 'white' }}
          >
            <span className="icon has-text-danger" style={{ margin: '2rem 0' }}>
              <i className="far fa-3x fa-times-circle"></i>
            </span>
            <h3 className="subtitle is-3">Ne cerem scuze, a aparut o eroare. </h3>
            <h3 className="subtitle is-3">Va rugam incercati mai tarziu. </h3>
            <h3 className="subtitle is-3">Administratorul site-ului a fost anuntat.</h3>
            <hr />
            {/* <div className="field is-grouped is-grouped-centered">
              In 5 secunde vei fi redirecționat către pagina principală
            </div> */}
            <div className="field is-grouped is-grouped-centered">
              <button className="button is-light" type="button" onClick={() => (window.location.href = payReferrer)}>
                Inapoi la pagina principală
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (hasPaymentError) {
    return (
      <div className="container">
        <div className="columns box-center-items">
          <div
            className="column is-two-thirds box-center has-text-centered"
            style={{ marginTop: '2rem', backgroundColor: 'white' }}
          >
            <span className="icon has-text-danger" style={{ margin: '2rem 0' }}>
              <i className="far fa-3x fa-times-circle"></i>
            </span>
            <h3 className="subtitle is-3">Plata a esuat</h3>
            <hr />
            <div className="field is-grouped is-grouped-centered">
              <button className="button is-light" type="button" onClick={() => (window.location.href = payReferrer)}>
                Inapoi la pagina principală
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (hasPaymentSuccess) {
    return (
      <div className="container">
        <div className="columns box-center-items">
          <div
            className="column is-two-thirds box-center has-text-centered"
            style={{ marginTop: '2rem', backgroundColor: 'white' }}
          >
            <span className="icon has-text-success" style={{ margin: '2rem 0' }}>
              <i className="far fa-3x fa-check-circle"></i>
            </span>
            <h3 className="subtitle is-3">Plata efectuată cu succes!</h3>
            <h3 className="subtitle is-3">Comanda nr. {model.orderNumber}</h3>
            <h5 className="subtitle is-5">Total comandă cu TVA inclus: {model.amount} Lei</h5>
            <h5 className="subtitle is-5">Plasata pe: {model.date}</h5>
            <hr />
            <div className="field is-grouped is-grouped-centered">
              <button className="button is-light" type="button" onClick={() => (window.location.href = payReferrer)}>
                Inapoi la pagina principală
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="container">
        <div className="columns box-center-items">
          <div className="column is-two-thirds box" style={{ marginTop: '2rem', backgroundColor: 'white' }}>
            <div className="element is-loading"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export { Pay };

import create, { SetState, GetState } from 'zustand';
import { LocalStorageService } from '../services';
import { Language, SessionState } from '../models';

const store = (set: SetState<SessionState>, get: GetState<SessionState>): SessionState => ({
  referrer: LocalStorageService.getSessionState().referrer,
  payReferrer: LocalStorageService.getSessionState().payReferrer,
  language: LocalStorageService.getSessionState().language,
  setReferrer: (url: string): void => {
    set((state) => ({ ...state, referrer: url }));
    LocalStorageService.saveState(get());
  },
  setPayReferrer: (url: string): void => {
    set((state) => ({ ...state, payReferrer: url }));
    LocalStorageService.saveState(get());
  },
  setLanguage: (language: Language): void => {
    set((state) => ({ ...state, language }));
    LocalStorageService.saveState(get());
  },
});

const [useSessionStore] = create(store);

export { useSessionStore };

import axios, { AxiosRequestConfig } from 'axios';
import Config from '../config';
import { SearchCompany } from '../models';

class CompanyService {
  static async getInfo(pattern: string): Promise<SearchCompany[]> {
    const opts: AxiosRequestConfig = {
      method: 'GET',
      baseURL: Config.ApiBaseUrl,
      url: `/get-details?name=${pattern}`,
    };
    const result = await axios.request<SearchCompany[]>(opts);
    return result.data;
  }
}

export { CompanyService };

import create, { SetState, GetState } from 'zustand';
import axios, { AxiosRequestConfig } from 'axios';
import Config from '../config';

import { EventState, Event, RegisterPayload, OrderResult, User } from '../models';

const store = (set: SetState<EventState>, get: GetState<EventState>): EventState => ({
  event: {} as Event,
  isLoading: true,
  isRegistering: false,
  load: async (id: number): Promise<void> => {
    const opts: AxiosRequestConfig = {
      method: 'GET',
      baseURL: Config.ApiBaseUrl,
      url: `events/${id}`,
    };
    const result = await axios.request<Event>(opts);
    set((state) => ({ ...state, event: result.data, isLoading: false }));
  },
  register: async (payload: RegisterPayload): Promise<OrderResult> => {
    set((state) => ({ ...state, isRegistering: true }));
    const opts: AxiosRequestConfig = {
      method: 'POST',
      baseURL: Config.ApiBaseUrl,
      url: `events`,
      data: payload,
    };
    try {
      const result = await axios.request<OrderResult>(opts);
      set((state) => ({ ...state, isRegistering: false }));
      return result.data;
    } catch (e) {
      set((state) => ({ ...state, isRegistering: false }));
      throw Error(e);
    }
  },
  addParticipants: async (payload: User[], eventId: string): Promise<void> => {
    const opts: AxiosRequestConfig = {
      method: 'POST',
      baseURL: Config.ApiBaseUrl,
      url: `events/${eventId}/participants`,
      data: payload,
    };
    try {
      await axios.request(opts);
    } catch (e) {
      console.error('#AddParticipants error', e);
    }
  },
});

const [useEventStore] = create(store);

export { useEventStore };

import { Language } from '../models';
import { LocalStorageService } from '../services/LocalStorageService';

import ro from '../config/languages/ro.json';
import en from '../config/languages/en.json';

const dictionary = {} as any;

dictionary[Language.RO] = ro;
dictionary[Language.EN] = en;

class Labels {
  static t(key: string): string {
    const sessionState = LocalStorageService.getSessionState();
    const langDic = dictionary[sessionState.language];
    if (!langDic) return key;

    const label = langDic[key];
    if (!label) return key;
    return label;
  }
}

export { Labels };

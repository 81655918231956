import React from 'react';
import { User } from '../models';
import * as ValidationPatterns from '../utils/ValidationPatterns';
import { Labels } from '../utils/Labels';

type ComponentProps = {
  index: number;
  style?: React.CSSProperties;
  hasDeleteButton: boolean;
  onDeleteClick: Function;
  user: User;
  register: Function;
  errors: any;
  isHybrid?: boolean;
};

const Participant = (props: React.PropsWithChildren<ComponentProps>): React.ReactElement => {
  return (
    <React.Fragment>
      <div className="columns is-multiline">
        <div className="column is-6">
          <div className="field">
            <label className="label">{Labels.t('participant.lastname')}</label>
            <div className="control">
              <input
                className={`input is-small  ${props.errors.lastName && 'is-danger'}`}
                type="text"
                name={`datasource[${props.index}].lastName`}
                placeholder="ex: Ionescu"
                defaultValue={props.user.lastName}
                ref={props.register({ required: true })}
              />
            </div>
            {props.errors.lastName && <p className="help is-danger">{Labels.t('form.mandatory')}</p>}
          </div>
        </div>
        <div className="column is-6">
          <div className="field">
            <label className="label">{Labels.t('participant.fistname')}</label>
            <div className="control">
              <input
                className={`input is-small  ${props.errors.firstName && 'is-danger'}`}
                type="text"
                name={`datasource[${props.index}].firstName`}
                placeholder="ex: Vasile"
                defaultValue={props.user.firstName}
                ref={props.register({ required: true })}
              />
            </div>
            {props.errors.firstName && <p className="help is-danger">{Labels.t('form.mandatory')}</p>}
          </div>
        </div>

        <div className="column is-6">
          <div className="field">
            <label className="label">{Labels.t('participant.email')}</label>
            <div className="control">
              <input
                className={`input is-small  ${props.errors.email && 'is-danger'}`}
                type="text"
                name={`datasource[${props.index}].email`}
                placeholder="ex: vasile.ionescu@gmail.com"
                defaultValue={props.user.email}
                ref={props.register({ required: true, pattern: ValidationPatterns.EMAIL })}
              />
            </div>
            {props.errors.email && <p className="help is-danger">{Labels.t('form.mandatory')}</p>}
          </div>
        </div>
        <div className="column is-6">
          <div className="field">
            <label className="label">
              {Labels.t('participant.phone')}
              <span className="icon has-text-info has-tooltip-arrow" data-tooltip={Labels.t('participant.info')}>
                <i className="far fa-question-circle"></i>
              </span>
            </label>

            <div className="control">
              <input
                className={`input is-small  ${props.errors.phone && 'is-danger'}`}
                type="number"
                name={`datasource[${props.index}].phone`}
                placeholder="ex: 0711222333"
                defaultValue={props.user.phone}
                ref={props.register({ required: true, pattern: ValidationPatterns.PHONE })}
              />
            </div>
            {props.errors.phone && <p className="help is-danger">{Labels.t('form.mandatory')}</p>}
          </div>
        </div>

        {props.isHybrid && (
          <div className="column is-6">
            <div className="field">
              <label className="label">{Labels.t('participants.attendType')}:</label>
              <div className="control">
                <div className={`select is-small ${props.errors.attendType && 'is-danger'}`}>
                  <select
                    ref={props.register({ required: true })}
                    defaultValue={''}
                    name={`datasource[${props.index}].attendType`}
                  >
                    <option value={''}>{Labels.t('participants.choose')}</option>
                    <option value={'online'}>Online</option>
                    <option value={'fizic'}>{Labels.t('participants.atLocation')}</option>
                  </select>
                </div>

                {props.errors.attendType && <p className="help is-danger">{Labels.t('form.mandatory')}</p>}
              </div>
            </div>
          </div>
        )}
      </div>

      {props.hasDeleteButton && (
        <div className="columns">
          <div className="column">
            <button className="button is-pulled-right" onClick={() => props.onDeleteClick(props.user.id)}>
              <span className="icon is-small">
                <i className="far fa-trash-alt"></i>
              </span>
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export { Participant };

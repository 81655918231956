import { v4 as uuidv4 } from 'uuid';

export class User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  city: string;
  county: string;
  address: string;
  sessionId: string;
  attendType: string;

  constructor() {
    this.id = uuidv4();
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.phone = '';
    this.county = '';
    this.city = '';
    this.address = '';
    this.sessionId = '';
    this.attendType = '';
  }
}

import { County } from '../models';

class Nomeclatures {
  static getCounties(): County[] {
    const counties: County[] = [];
    let county: County;
    county = { name: 'ALTUL/OTHER', value: '-' };
    counties.push(county);
    county = { name: 'ALBA', value: 'ALBA' };
    counties.push(county);
    county = { name: 'BRASOV', value: 'BRASOV' };
    counties.push(county);
    county = { name: 'BOTOSANI', value: 'BOTOSANI' };
    counties.push(county);
    county = { name: 'ARAD', value: 'ARAD' };
    counties.push(county);
    county = { name: 'ARGES', value: 'ARGES' };
    counties.push(county);
    county = { name: 'BACAU', value: 'BACAU' };
    counties.push(county);
    county = { name: 'BIHOR', value: 'BIHOR' };
    counties.push(county);
    county = { name: 'BISTRITA-NASAUD', value: 'BISTRITA-NASAUD' };
    counties.push(county);
    county = { name: 'BRAILA', value: 'BRAILA' };
    counties.push(county);
    county = { name: 'BUZAU', value: 'BUZAU' };
    counties.push(county);
    county = { name: 'CALARASI', value: 'CALARASI' };
    counties.push(county);
    county = { name: 'CARAS-SEVERIN', value: 'CARAS-SEVERIN' };
    counties.push(county);
    county = { name: 'CLUJ', value: 'CLUJ' };
    counties.push(county);
    county = { name: 'CONSTANTA', value: 'CONSTANTA' };
    counties.push(county);
    county = { name: 'COVASNA', value: 'COVASNA' };
    counties.push(county);
    county = { name: 'DAMBOVITA', value: 'DAMBOVITA' };
    counties.push(county);
    county = { name: 'DOLJ', value: 'DOLJ' };
    counties.push(county);
    county = { name: 'GALATI', value: 'GALATI' };
    counties.push(county);
    county = { name: 'GIURGIU', value: 'GIURGIU' };
    counties.push(county);
    county = { name: 'GORJ', value: 'GORJ' };
    counties.push(county);
    county = { name: 'HARGHITA', value: 'HARGHITA' };
    counties.push(county);
    county = { name: 'HUNEDOARA', value: 'HUNEDOARA' };
    counties.push(county);
    county = { name: 'IALOMITA', value: 'IALOMITA' };
    counties.push(county);
    county = { name: 'IASI', value: 'IASI' };
    counties.push(county);
    county = { name: 'ILFOV', value: 'ILFOV' };
    counties.push(county);
    county = { name: 'MARAMURES', value: 'MARAMURES' };
    counties.push(county);
    county = { name: 'MEHEDINTI', value: 'MEHEDINTI' };
    counties.push(county);
    county = { name: 'MURES', value: 'MURES' };
    counties.push(county);
    county = { name: 'NEAMT', value: 'NEAMT' };
    counties.push(county);
    county = { name: 'OLT', value: 'OLT' };
    counties.push(county);
    county = { name: 'PRAHOVA', value: 'PRAHOVA' };
    counties.push(county);
    county = { name: 'SALAJ', value: 'SALAJ' };
    counties.push(county);
    county = { name: 'SATU MARE', value: 'SATU MARE' };
    counties.push(county);
    county = { name: 'SIBIU', value: 'SIBIU' };
    counties.push(county);
    county = { name: 'SUCEAVA', value: 'SUCEAVA' };
    counties.push(county);
    county = { name: 'TELEORMAN', value: 'TELEORMAN' };
    counties.push(county);
    county = { name: 'TIMIS', value: 'TIMIS' };
    counties.push(county);
    county = { name: 'TULCEA', value: 'TULCEA' };
    counties.push(county);
    county = { name: 'VALCEA', value: 'VALCEA' };
    counties.push(county);
    county = { name: 'VASLUI', value: 'VASLUI' };
    counties.push(county);
    county = { name: 'VRANCEA', value: 'VRANCEA' };
    counties.push(county);
    county = { name: 'BUCURESTI - SECTOR 1', value: 'SECTOR 1' };
    counties.push(county);
    county = { name: 'BUCURESTI - SECTOR 2', value: 'SECTOR 2' };
    counties.push(county);
    county = { name: 'BUCURESTI - SECTOR 3', value: 'SECTOR 3' };
    counties.push(county);
    county = { name: 'BUCURESTI - SECTOR 4', value: 'SECTOR 4' };
    counties.push(county);
    county = { name: 'BUCURESTI - SECTOR 5', value: 'SECTOR 5' };
    counties.push(county);
    county = { name: 'BUCURESTI - SECTOR 6', value: 'SECTOR 6' };
    counties.push(county);
    return counties;
  }
}

export { Nomeclatures };

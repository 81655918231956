import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import { WizardContainer, Participant } from '../components';
import { useRegisterStore, useEventStore, useSessionStore } from '../store';
import { User, Event, RegisterState, EventState } from '../models';
import { RegisterService } from '../services';
import { Labels } from '../utils/Labels';

type ComponentProps = {};

type DynamicFormItem = {
  [id: string]: User[];
};

const Participants = (props: React.PropsWithChildren<ComponentProps>): React.ReactElement => {
  const currentStep = 0;
  const history = useHistory();
  const { id } = useParams();
  const { language } = useSessionStore();

  const participants = useRegisterStore((state: RegisterState) => state.participants);
  const setParticipants = useRegisterStore((state: RegisterState) => state.setParticipants);
  const addParticipants = useEventStore((state: EventState) => state.addParticipants);
  const event = useEventStore((state: EventState) => state.event);

  const { register, control, errors, handleSubmit } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'datasource',
  });

  // Effects
  useEffect(() => {
    if (participants.length) {
      append(participants);
    } else {
      append(new User());
    }
  }, []);

  const onSubmit = (data: DynamicFormItem): void => {
    if (!id || !setParticipants) return;
    setParticipants(data.datasource);
    addParticipants(data.datasource, id);

    const route = RegisterService.getNextRoute(currentStep, event.id, event.hasPayment);
    history.push(route);
  };

  return (
    <WizardContainer stepIndex={currentStep}>
      <div className="column is-four-fifths box-center">
        {event.isHybrid && event.targetSize && event.targetSize > 0 && event.availableSize && event.availableSize <= 10 ? (
          <div className="field">
            <div className="content">
              <blockquote>
                {Labels.t('confirmation.size.start')} <strong>{event.availableSize}</strong>{' '}
                {Labels.t('confirmation.size.end')}.
              </blockquote>
            </div>
          </div>
        ) : null}

        <h2 className="subtitle">
          <p>{Labels.t('participants.title')}</p>
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields.map((participant: any, index: number) => (
            <div key={participant.id}>
              <hr />
              <Participant
                index={index}
                hasDeleteButton={index !== 0}
                user={participant}
                onDeleteClick={(id: string): void => remove(index)}
                register={register}
                errors={errors.datasource && errors.datasource[index] ? errors.datasource[index] : {}}
                isHybrid={event.isHybrid}
              />
            </div>
          ))}

          <hr />

          <div className="field is-grouped is-grouped-centered">
            <p className="control">
              <button className="button" type="button" onClick={(): void => append(new User())}>
                {Labels.t('participants.add')}
              </button>
            </p>
          </div>

          <hr />

          <div className="field is-grouped is-grouped-centered">
            <p className="control">
              <button className="button is-primary" type="submit">
                {Labels.t('form.next')}
              </button>
            </p>
          </div>
        </form>
      </div>
    </WizardContainer>
  );
};

export { Participants };

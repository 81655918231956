import { v4 as uuidv4 } from 'uuid';
import create, { SetState, GetState } from 'zustand';
import { RegisterState, RegisterType, User, PaymentType, Company } from '../models';

const updateSessionId = (): void => {
  const sessionId = uuidv4();
  localStorage.setItem('sessionId', sessionId);
};

const getSessionId = (): string => {
  let sessionId = localStorage.getItem('sessionId');
  if (!sessionId) {
    updateSessionId();
    sessionId = localStorage.getItem('sessionId');
  }
  return sessionId || '-';
};

const setSessionId = (users: User[]): void => {
  const sessionId = getSessionId();
  users.forEach((u) => (u.sessionId = sessionId));
};

const saveState = (state: RegisterState): void => {
  try {
    const jsonState = JSON.stringify(state);
    localStorage.setItem('state', jsonState);
  } catch (error) {
    console.error(error);
  }
};

const getSavedState = (): RegisterState => {
  let state: RegisterState = {
    type: RegisterType.PF,
    participants: [],
    person: undefined,
    company: undefined,
    paymentType: PaymentType.CC,
  };

  try {
    const stringState = localStorage.getItem('state');
    if (stringState) {
      const localState = JSON.parse(stringState) as RegisterState;
      if (!localState || localState != null) {
        state = localState;
      }
    }
  } catch (error) {
    console.error(error);
  }

  return state;
};

const store = (set: SetState<RegisterState>, get: GetState<RegisterState>): RegisterState => ({
  type: getSavedState().type,
  participants: getSavedState().participants,
  person: getSavedState().person,
  company: getSavedState().company,
  paymentType: getSavedState().paymentType,
  setParticipants: (users: User[]): void => {
    setSessionId(users);
    let billingPerson: User;
    if (users.length) {
      billingPerson = users[0];
    }
    set((state) => ({ ...state, participants: users, person: billingPerson }));
    saveState(get());
  },
  setType: (type: RegisterType): void => {
    set((state) => ({ ...state, type }));
    saveState(get());
  },
  setBillingData: (person: User, company: Company): void => {
    set((state) => ({ ...state, person, company }));
    saveState(get());
  },
  setPaymentType: (paymentType: PaymentType): void => {
    set((state) => ({ ...state, paymentType }));
    saveState(get());
  },
  stepIsValid: (index: number): boolean => {
    if (index === 1 && !get().participants.length) return false;
    if (index === 2 && !get().person) return false;
    if (index === 3 && !get().person) return false;
    // if (index === 4 && !get().person) return false;
    return true;
  },
  clear: (): void => {
    set((state) => ({
      ...state,
      participants: [],
      person: undefined,
      company: undefined,
      type: RegisterType.PF,
      paymentType: PaymentType.CC,
    }));
    saveState(get());
    updateSessionId();
  },
});

const [useRegisterStore] = create(store);

export { useRegisterStore };

import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Wizard } from './Wizard';
import { Alert } from './Alert';
import { useSessionStore, useEventStore, useRegisterStore } from '../store';
import { RegisterService } from '../services';
import { Language, RegisterState } from '../models';
import { Labels } from '../utils/Labels';

type ComponentProps = {
  stepIndex: number;
  hideDismissButton?: boolean;
};

const WizardContainer = (props: React.PropsWithChildren<ComponentProps>): React.ReactElement => {
  const history = useHistory();
  const { id: eventId } = useParams();

  const { isLoading, event, load } = useEventStore();
  const { referrer, setReferrer, language, setLanguage } = useSessionStore();
  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  const stepIsValid = useRegisterStore((state: RegisterState) => state.stepIsValid);
  const steps = RegisterService.getSteps(event.id ? event.hasPayment : false);
  const currentStep = RegisterService.getCurrentStep(props.stepIndex, event.id ? event.hasPayment : false);

  const redirectToEvent = (eventId?: string): void => {
    if (!referrer) {
      const eventPath = { pathname: `/events/${eventId}/participants` };
      return history.replace(eventPath);
    }
    if (setReferrer) setReferrer('');
    window.location.href = referrer;
  };

  const changeLanguage = (newLanguage: Language): void => {
    if (newLanguage !== language && setLanguage) {
      setLanguage(newLanguage);
    }
  };

  // Validate current step
  if (event.id && stepIsValid && !stepIsValid(props.stepIndex)) {
    history.push(`/events/${event.id}/participants`);
  }

  useEffect(() => {
    if ((!referrer || document.referrer.includes('evenimente') || document.referrer.includes('teste')) && setReferrer) {
      setReferrer(document.referrer);
    }
    if (!event.id) {
      load(Number(eventId));
    }
  }, []);

  if (isLoading) {
    return (
      <React.Fragment>
        <div className="container">
          <div className="columns box-center-items">
            <div className="column is-two-thirds box" style={{ marginTop: '2rem', backgroundColor: 'white' }}>
              <div className="element is-loading"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Alert
        isActive={isModalActive}
        onCancelClick={() => setIsModalActive(false)}
        onOkClick={() => redirectToEvent(eventId)}
      >
        <div className="has-text-centered	">
          <h3 className="subtitle is-3">{Labels.t('alert.quit')}</h3>
        </div>
      </Alert>
      <div className="container">
        <div className="columns box-center-items">
          <div className="column is-two-thirds box" style={{ marginTop: '2rem', backgroundColor: 'white' }}>
            <nav className="level">
              <div className="level-left">
                <div className="level-item">
                  {!props.hideDismissButton ? (
                    <button className="button" style={{ marginBottom: '1rem' }} onClick={() => setIsModalActive(true)}>
                      <span className="icon">
                        <i className="far fa-times-circle"></i>
                      </span>
                    </button>
                  ) : (
                    <div style={{ marginBottom: '1rem' }}>&nbsp;</div>
                  )}
                </div>
              </div>

              <div className="level-right">
                <p className="level-item">
                  <img
                    src="/assets/ro.svg"
                    alt="RO"
                    className={language === Language.RO ? 'language-img language-selected ' : 'language-img'}
                    onClick={() => changeLanguage(Language.RO)}
                  />
                </p>
                <p className="level-item">
                  <img
                    src="/assets/en.svg"
                    alt="EN"
                    className={language === Language.EN ? 'language-img language-selected ' : 'language-img'}
                    onClick={() => changeLanguage(Language.EN)}
                  />
                </p>
              </div>
            </nav>

            <div className="has-text-centered" style={{ marginBottom: '1.5rem' }}>
              <h3 className="title">{event.title}</h3>
            </div>

            <Wizard index={currentStep} steps={steps} />

            <div className="columns box-center-items">{props.children}</div>
          </div>
        </div>
        <div className="columns">
          <div className="column">&nbsp;</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export { WizardContainer };

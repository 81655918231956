import create, { SetState, GetState } from 'zustand';
import { PaymentState, OrderResult } from '../models';
import { PaymentService } from '../services';

const store = (set: SetState<PaymentState>, get: GetState<PaymentState>): PaymentState => ({
  hasFatalError: false,
  isChecking: true,
  payment: undefined,
  checkPayment: async (orderId: string): Promise<void> => {
    try {
      const result = await PaymentService.verifyPayment(orderId);
      set((state) => ({ ...state, payment: result, isChecking: false }));
    } catch (e) {
      set((state) => ({ ...state, hasFatalError: true }));
    }
  },
});

const [usePaymentStore] = create(store);

export { usePaymentStore };

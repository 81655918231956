import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './styles/App.scss';
import { Home, Event, Participants, BillingData, Payment, Summary, Confirmation, Pay } from './screens';

function App(): React.ReactElement {
  return (
    <React.Fragment>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <div className="navbar-item">
            <img src="/assets/logo-m.png" />
          </div>
        </div>
      </nav>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          {/* <Route exact path="/events/:id">
            <Event />
          </Route> */}
          <Route exact path="/events/:id/participants">
            <Participants />
          </Route>
          <Route exact path="/events/:id/billing">
            <BillingData />
          </Route>
          <Route exact path="/events/:id/payment">
            <Payment />
          </Route>
          <Route exact path="/events/:id/summary">
            <Summary />
          </Route>
          <Route exact path="/events/:id/confirmation">
            <Confirmation />
          </Route>
          <Route exact path="/pay">
            <Pay />
          </Route>
          <Route render={(): React.ReactElement => <h3>Not found</h3>} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;

import axios, { AxiosRequestConfig } from 'axios';
import Config from '../config';
import { PaymentRedirect, OrderResult } from '../models';

class PaymentService {
  static async requestInvoiceOrderNumber(invoiceId: string): Promise<PaymentRedirect> {
    const opts: AxiosRequestConfig = {
      method: 'POST',
      baseURL: Config.ApiBaseUrl,
      url: `/register-payment`,
      data: { invoiceId },
    };
    const result = await axios.request<PaymentRedirect>(opts);
    return result.data;
  }

  static async verifyPayment(orderId: string): Promise<OrderResult> {
    const opts: AxiosRequestConfig = {
      method: 'POST',
      baseURL: Config.ApiBaseUrl,
      url: `/verify-payment`,
      data: { orderId },
    };
    const result = await axios.request<OrderResult>(opts);
    return result.data;
  }
}

export { PaymentService };

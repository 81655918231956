import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import { WizardContainer } from '../components';
import { useRegisterStore, Nomeclatures, useEventStore, useSessionStore } from '../store';
import { RegisterState, RegisterType, User, Company, SearchCompany, EventState } from '../models';
import * as ValidationPatterns from '../utils/ValidationPatterns';
import { CompanyService } from '../services';
import { Alert } from '../components';
import { LanguageHelper } from '../utils/LanguageHelper';
import { RegisterService } from '../services';
import { Labels } from '../utils/Labels';

type FormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  cif: string;
  legalName: string;
  legalNumber: string;
  city: string;
  county: string;
  pfCity: string;
  pfCounty: string;
  companyAddress: string;
};

const BillingData = (): React.ReactElement => {
  const currentStep = 1;
  const history = useHistory();
  const { id } = useParams();
  const type = useRegisterStore((state: RegisterState) => state.type);
  const setBillingData = useRegisterStore((state: RegisterState) => state.setBillingData);
  const billingPerson = useRegisterStore((state: RegisterState) => state.person);
  const billingCompany = useRegisterStore((state: RegisterState) => state.company);
  const setType = useRegisterStore((state: RegisterState) => state.setType);
  const event = useEventStore((state: EventState) => state.event);
  const { language } = useSessionStore();
  const { register, handleSubmit, errors, getValues, setValue } = useForm<FormInputs>();
  const [listCompanies, setListCompanies] = useState<SearchCompany[]>([]);
  const [showCompaniesModal, setShowCompaniesModal] = useState<boolean>(false);

  const onSubmit = (data: FormInputs): void => {
    const person = new User();
    person.firstName = data.firstName;
    person.lastName = data.lastName;
    person.email = data.email;
    person.phone = data.phone;
    person.address = data.address;
    person.city = data.pfCity;
    person.county = data.pfCounty;

    const company: Company = {
      cif: data.cif,
      legalName: data.legalName,
      legalNumber: data.legalNumber,
      address: data.companyAddress,
      county: data.county,
      city: data.city,
    };

    if (!setBillingData) return;
    setBillingData(person, company);

    const route = RegisterService.getNextRoute(currentStep, event.id, event.hasPayment);
    history.push(route);

    // history.push(`/events/${id}/payment`);
  };

  const searchCompany = async (): Promise<void> => {
    const name = getValues('legalName');
    if (!name || name.length <= 3) return;

    const result = await CompanyService.getInfo(name);
    if (result.length > 1) {
      setListCompanies(result);
      setShowCompaniesModal(true);
    }

    if (result.length === 1) {
      fillCompanyData(result[0]);
    }
  };

  const fillCompanyData = (input: SearchCompany): void => {
    try {
      setValue('legalName', input.name);
      setValue('legalNumber', input.nrregcom);
      setValue('cif', input.cui);
      setValue('city', input.city);

      const city = LanguageHelper.normalize(input.city);
      let county = LanguageHelper.normalize(input.county);
      county =
        county === 'MUNICIPIUL BUCURESTI'
          ? city.replace('Bucuresti ', '').toUpperCase().replace('SECTORUL', 'SECTOR')
          : county;

      setValue('county', county);
      setValue('companyAddress', input.address);
    } catch (e) {}

    if (showCompaniesModal) setShowCompaniesModal(false);
  };

  if (!setType) return <></>;

  const title = event.hasPayment ? Labels.t('billing.invoice.data') : Labels.t('billing.account');

  return (
    <WizardContainer stepIndex={currentStep}>
      <Alert isActive={showCompaniesModal} hasOnlyClose={true} onCancelClick={() => setShowCompaniesModal(false)}>
        <div className="has-text-centered	">
          <table className="table is-hoverable">
            <tbody>
              {listCompanies.map((c, i) => (
                <tr key={i} onClick={() => fillCompanyData(c)} style={{ cursor: 'pointer' }}>
                  <td>{c.name}</td>
                  <td>{c.cui}</td>
                  <td>{c.city}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Alert>

      <div className="column is-four-fifths box-center">
        <h2 className="subtitle">{title}</h2>

        <div className="tabs is-toggle is-centered">
          <ul>
            <li className={type === RegisterType.PF ? 'is-active' : ''} onClick={() => setType(RegisterType.PF)}>
              <a>
                <span className="icon is-small">
                  <i className="far fa-user" aria-hidden="true"></i>
                </span>
                <span>{Labels.t('billing.person')}</span>
              </a>
            </li>
            <li className={type === RegisterType.PJ ? 'is-active' : ''} onClick={() => setType(RegisterType.PJ)}>
              <a>
                <span className="icon is-small">
                  <i className="far fa-building" aria-hidden="true"></i>
                </span>
                <span>{Labels.t('billing.company')}</span>
              </a>
            </li>
          </ul>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          {type === RegisterType.PJ && (
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label">
                    {Labels.t('billing.company.name')}
                    <span className="icon has-text-info has-tooltip-arrow" data-tooltip={Labels.t('billing.company.search')}>
                      <i className="far fa-question-circle"></i>
                    </span>
                  </label>
                  <div className="control">
                    <input
                      className={`input is-small  ${errors.legalName && 'is-danger'}`}
                      type="text"
                      name="legalName"
                      placeholder="ex: Avensis SRL"
                      ref={register({ required: true })}
                      defaultValue={billingCompany?.legalName}
                      tabIndex={1}
                      onBlur={() => searchCompany()}
                    />
                  </div>
                  {errors.legalName && <p className="help is-danger">{Labels.t('form.mandatory')}</p>}
                </div>

                <div className="field">
                  <label className="label">{Labels.t('billing.county')}</label>
                  <div className="control">
                    <div className="select is-small is-fullwidth">
                      <select
                        name="county"
                        ref={register({ required: true })}
                        defaultValue={billingCompany?.county}
                        tabIndex={4}
                      >
                        {Nomeclatures.getCounties().map((c) => {
                          return (
                            <option key={c.value} value={c.value}>
                              {c.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {errors.county && <p className="help is-danger">{Labels.t('form.mandatory')}</p>}
                </div>
              </div>

              <div className="column">
                <div className="field">
                  <label className="label">{Labels.t('billing.cif')}</label>
                  <div className="control">
                    <input
                      className={`input is-small  ${errors.cif && 'is-danger'}`}
                      type="text"
                      name="cif"
                      placeholder="ex: 14994406"
                      ref={register({ required: true, pattern: ValidationPatterns.CIF })}
                      defaultValue={billingCompany?.cif}
                      tabIndex={2}
                    />
                  </div>
                  {errors.cif && <p className="help is-danger">{Labels.t('form.mandatory')}</p>}
                </div>

                <div className="field">
                  <label className="label">{Labels.t('billing.city')}</label>
                  <div className="control">
                    <input
                      className={`input is-small  ${errors.city && 'is-danger'}`}
                      type="text"
                      name="city"
                      placeholder="ex: Bucuresti"
                      ref={register({ required: true })}
                      defaultValue={billingCompany?.city}
                      tabIndex={5}
                    />
                  </div>
                  {errors.city && <p className="help is-danger">{Labels.t('form.mandatory')}</p>}
                </div>
              </div>

              <div className="column">
                <div className="field">
                  <label className="label">{Labels.t('billing.company.number')} </label>
                  <div className="control">
                    <input
                      className={`input is-small  ${errors.legalNumber && 'is-danger'}`}
                      type="text"
                      name="legalNumber"
                      placeholder="ex: J40/4017/1996"
                      ref={register({ required: true })}
                      defaultValue={billingCompany?.legalNumber}
                      tabIndex={3}
                    />
                  </div>
                  {errors.legalNumber && <p className="help is-danger">{Labels.t('form.mandatory')}</p>}
                </div>

                <div className="field">
                  <label className="label">{Labels.t('billing.address')}</label>
                  <div className="control">
                    <input
                      className={`input is-small  ${errors.companyAddress && 'is-danger'}`}
                      type="text"
                      name="companyAddress"
                      placeholder="ex: str. Calea Voctoriei 1"
                      ref={register({ required: true })}
                      defaultValue={billingCompany?.address}
                      tabIndex={6}
                    />
                  </div>
                  {errors.companyAddress && <p className="help is-danger">{Labels.t('form.mandatory')}</p>}
                </div>
              </div>
            </div>
          )}

          <div className="columns">
            <div className="column">
              <div className="field">
                <label className="label">{Labels.t('billing.lastname')}</label>
                <div className="control">
                  <input
                    className={`input is-small  ${errors.lastName && 'is-danger'}`}
                    type="text"
                    name="lastName"
                    placeholder="ex: Ionescu"
                    ref={register({ required: true })}
                    defaultValue={billingPerson?.lastName}
                    tabIndex={7}
                  />
                </div>
                {errors.lastName && <p className="help is-danger">{Labels.t('form.mandatory')}</p>}
              </div>

              <div className="field">
                <label className="label">{Labels.t('billing.phone')}</label>
                <div className="control">
                  <input
                    className={`input is-small  ${errors.phone && 'is-danger'}`}
                    type="number"
                    name="phone"
                    placeholder="ex: 0711222333"
                    ref={register({ required: true, pattern: ValidationPatterns.PHONE })}
                    defaultValue={billingPerson?.phone}
                    tabIndex={10}
                  />
                </div>
                {errors.phone && <p className="help is-danger">{Labels.t('form.mandatory')}</p>}
              </div>

              {type === RegisterType.PF && (
                <div className="field">
                  <label className="label">{Labels.t('billing.address')}</label>
                  <div className="control">
                    <input
                      className={`input is-small  ${errors.address && 'is-danger'}`}
                      type="text"
                      name="address"
                      placeholder="ex: str. Calea Voctoriei 1"
                      ref={register({ required: true })}
                      defaultValue={billingPerson?.address}
                      tabIndex={13}
                    />
                  </div>
                  {errors.address && <p className="help is-danger">{Labels.t('form.mandatory')}</p>}
                </div>
              )}
            </div>

            <div className="column">
              <div className="field">
                <label className="label">{Labels.t('billing.firstname')}</label>
                <div className="control">
                  <input
                    className={`input is-small  ${errors.firstName && 'is-danger'}`}
                    type="text"
                    name="firstName"
                    placeholder="ex: Vasile"
                    ref={register({ required: true })}
                    defaultValue={billingPerson?.firstName}
                    tabIndex={8}
                  />
                </div>
                {errors.firstName && <p className="help is-danger">{Labels.t('form.mandatory')}</p>}
              </div>

              {type === RegisterType.PF && (
                <div className="field">
                  <label className="label">{Labels.t('billing.county')}</label>
                  <div className="control">
                    <div className="select is-small is-fullwidth">
                      <select
                        name="pfCounty"
                        ref={register({ required: true })}
                        defaultValue={billingPerson?.county}
                        tabIndex={11}
                      >
                        {Nomeclatures.getCounties().map((c) => {
                          return (
                            <option key={c.value} value={c.value}>
                              {c.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {errors.county && <p className="help is-danger">{Labels.t('form.mandatory')}</p>}
                </div>
              )}
            </div>

            <div className="column">
              <div className="field">
                <label className="label">{Labels.t('billing.email')}</label>
                <div className="control">
                  <input
                    className={`input is-small  ${errors.email && 'is-danger'}`}
                    type="text"
                    name="email"
                    placeholder="ex: vasile.ionescu@gmail.com"
                    ref={register({ required: true, pattern: ValidationPatterns.EMAIL })}
                    defaultValue={billingPerson?.email}
                    tabIndex={9}
                  />
                </div>
                {errors.email && <p className="help is-danger">{Labels.t('form.mandatory')}</p>}
              </div>

              {type === RegisterType.PF && (
                <div className="field">
                  <label className="label">{Labels.t('billing.city')}</label>
                  <div className="control">
                    <input
                      className={`input is-small  ${errors.pfCity && 'is-danger'}`}
                      type="text"
                      name="pfCity"
                      placeholder="ex: Bucuresti"
                      ref={register({ required: true })}
                      defaultValue={billingPerson?.city}
                      tabIndex={12}
                    />
                  </div>
                  {errors.pfCity && <p className="help is-danger">{Labels.t('form.mandatory')}</p>}
                </div>
              )}
            </div>
          </div>

          <hr />
          <div className="field is-grouped is-grouped-centered">
            <p className="control">
              <button
                className="button is-light"
                type="button"
                onClick={() => history.replace(`/events/${id}/participants`)}
                tabIndex={19}
              >
                {Labels.t('form.previous')}
              </button>
            </p>
            <p className="control">
              <button className="button is-primary" type="submit" tabIndex={20}>
                {Labels.t('form.next')}
              </button>
            </p>
          </div>
        </form>
      </div>
    </WizardContainer>
  );
};

export { BillingData };

import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useRegisterStore, useEventStore, useSessionStore } from '../store';
import { WizardContainer } from '../components';
import { PaymentType, RegisterType, RegisterPayload } from '../models';
import { RegisterService } from '../services';
import { Labels } from '../utils/Labels';

type ComponentProps = {};

const Summary = (props: React.PropsWithChildren<ComponentProps>): React.ReactElement => {
  const currentStep = 3;
  const history = useHistory();
  const { id } = useParams();
  const [hasPolicy, setHasPolicy] = useState<boolean>(false);
  const [hasTerms, setHasTerms] = useState<boolean>(false);
  const [hasContact, setHasContact] = useState<boolean>(false);
  const { participants, type, person, company, paymentType } = useRegisterStore();
  const { event, isLoading, isRegistering, register } = useEventStore();
  const { language } = useSessionStore();

  const canGoNext = hasPolicy && hasTerms;

  const backRoute = RegisterService.getBackRoute(currentStep, event.id, event.hasPayment);

  const goNext = async (): Promise<void> => {
    const payload: RegisterPayload = {
      eventId: Number(id),
      participants,
      person: person!,
      paymentType,
      type,
      company,
    };

    try {
      const orderResult = await register(payload);
      if (orderResult.hasRedirect) {
        window.location.href = orderResult.redirectUrl;
      } else {
        history.push({
          pathname: `/events/${id}/confirmation`,
          state: orderResult,
        });
      }
    } catch (error) {
      console.log('# Register error', error);
    }
  };

  const calculatePrice = (price: number, vat: number, no: number): string => {
    return ((price + vat) * no).toFixed(2).toString().replace('.', ',');
  };

  const calculateVat = (vat: number, no: number): string => {
    return (vat * no).toFixed(2).toString().replace('.', ',');
  };

  const title = event.hasPayment ? Labels.t('summary.title.order') : Labels.t('summary.title.register');

  return (
    <WizardContainer stepIndex={currentStep} hideDismissButton={isLoading || isRegistering}>
      <div className="column is-two-thirds box-center">
        <h2 className="subtitle">{title}</h2>

        {event.hasPayment && (
          <>
            <div className="summary-box">
              <p className="title is-5"> {Labels.t('summary.info')} </p>
              {type === RegisterType.PF && (
                <>
                  <p>
                    {person?.lastName} {person?.firstName} - {person?.phone}
                  </p>
                  <p>{person?.address}</p>
                  <p>{person?.email}</p>
                </>
              )}
              {type === RegisterType.PJ && (
                <>
                  <p>
                    {company?.legalName} - {company?.cif}
                  </p>
                  <p>
                    {company?.address}, {company?.city}, {company?.county}
                  </p>
                  <p>{person?.email}</p>
                </>
              )}
            </div>
            <div className="summary-box" style={{ marginTop: 8 }}>
              <p className="title is-5">{Labels.t('summary.payment')}</p>
              {paymentType === PaymentType.CC && (
                <>
                  <p>Card online</p>
                  <p>Dupa plasarea comenzii, vei fi redirectionat catre procesatorul de plati pentru a efectua plata.</p>
                </>
              )}
              {paymentType === PaymentType.OP && (
                <>
                  <p>{Labels.t('summary.paymentOrder')} </p>
                  <p>{Labels.t('summary.opinfo')}</p>
                </>
              )}
            </div>
            <div className="summary-box" style={{ marginTop: 8 }}>
              <p className="title is-5">{Labels.t('summary.participants')}</p>
              {participants.map((p, index) => (
                <p key={index}>
                  {p.lastName} {p.firstName} - {p.email}
                  {' - '}
                  {p.attendType && `${p.attendType == 'online' ? 'Online' : Labels.t('participants.atLocation')}`}
                </p>
              ))}
            </div>
            <div className="summary-box" style={{ marginTop: 8 }}>
              <p className="title is-5">{Labels.t('summary.items')}</p>
              <div className="level">
                <span>
                  {participants.length} X {event.title}
                </span>
                <span>{calculatePrice(event.price, event.priceVAT, participants.length)} Lei</span>
              </div>
            </div>
            <div className="summary-box" style={{ marginTop: 8 }}>
              <p className="title is-6">
                {Labels.t('summary.vat')}: {calculateVat(event.priceVAT, participants.length)} Lei
              </p>
              <p className="title is-5">
                {Labels.t('summary.total.with.vat')}: {calculatePrice(event.price, event.priceVAT, participants.length)} Lei
              </p>
              <div>
                <label className="checkbox">
                  <input type="checkbox" onClick={() => setHasPolicy(!hasPolicy)} />
                  &nbsp;{Labels.t('summary.policy.privacy')}
                  <br />
                  <a href={Labels.t('summary.policy.privacy.link')} target="_blank">
                    {Labels.t('summary.policy.privacy.view')}
                  </a>
                </label>
                <br />
                <label className="checkbox">
                  <input type="checkbox" onClick={() => setHasTerms(!hasTerms)} />
                  &nbsp;{Labels.t('summary.policy.terms')}
                  <br />
                  <a href={Labels.t('summary.policy.terms.link')} target="_blank">
                    {Labels.t('summary.policy.terms.view')}
                  </a>
                </label>
                <br />
                <label className="checkbox">
                  <input type="checkbox" onClick={() => setHasContact(!hasContact)} />
                  &nbsp;
                  {Labels.t('summary.policy.terms.contact')}
                </label>
              </div>
            </div>
          </>
        )}

        {!event.hasPayment && (
          <>
            <div className="summary-box" style={{ marginTop: 8 }}>
              <p className="title is-5">{Labels.t('summary.participants')}</p>
              {participants.map((p, index) => (
                <p key={index}>
                  {p.lastName} {p.firstName} - {p.email}
                </p>
              ))}
            </div>
            <div className="summary-box" style={{ marginTop: 8 }}>
              <div>
                <label className="checkbox">
                  <input type="checkbox" onClick={() => setHasPolicy(!hasPolicy)} />
                  &nbsp;{Labels.t('summary.policy.privacy')}
                  <br />
                  <a href={Labels.t('summary.policy.privacy.link')} target="_blank">
                    {Labels.t('summary.policy.privacy.view')}
                  </a>
                </label>
                <br />
                <label className="checkbox">
                  <input type="checkbox" onClick={() => setHasTerms(!hasTerms)} />
                  &nbsp;{Labels.t('summary.policy.terms')}
                  <br />
                  <a href={Labels.t('summary.policy.terms.link')} target="_blank">
                    {Labels.t('summary.policy.terms.view')}
                  </a>
                </label>
                <br />
                <label className="checkbox">
                  <input type="checkbox" onClick={() => setHasContact(!hasContact)} />
                  &nbsp;{Labels.t('summary.policy.terms.contact')}
                </label>
              </div>
            </div>
          </>
        )}

        <hr />
        <div className="field is-grouped is-grouped-centered">
          <p className="control">
            {!isRegistering && (
              <button className="button is-light" type="button" onClick={() => history.replace(backRoute)}>
                {Labels.t('form.previous')}
              </button>
            )}
          </p>
          <p className="control">
            <button
              disabled={!canGoNext}
              className={`button is-primary ${isRegistering && 'is-loading'}`}
              type="button"
              onClick={() => goNext()}
            >
              {Labels.t('summary.send')}
            </button>
          </p>
        </div>
      </div>
    </WizardContainer>
  );
};

export { Summary };

import React from 'react';

type ComponentProps = {
  isActive: boolean;
  onClick: Function;
  style?: React.CSSProperties;
};

const SelectButton = (props: React.PropsWithChildren<ComponentProps>): React.ReactElement => {
  return (
    <div
      className={`is-flex button-select ${props.isActive && 'is-active box-shadow'}`}
      style={props.style}
      onClick={(): void => props.onClick()}
    >
      <span className={`icon ${props.isActive && 'has-text-success'}`}>
        <i className={`far fa-2x ${props.isActive ? 'fa-check-circle' : 'fa-circle'}`}></i>
      </span>
      <div className="text-container">{props.children}</div>
    </div>
  );
};

export { SelectButton };

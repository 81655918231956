import React from 'react';

type ComponentProps = {
  index: number;
  steps: string[];
};

const Wizard = (props: React.PropsWithChildren<ComponentProps>): React.ReactElement => {
  return (
    <ul className="steps is-thin has-content-centered">
      {props.steps.map((step, index) => {
        const isActive = index === props.index ? 'is-active' : '';
        return (
          <li key={index} className={`steps-segment ${isActive}`}>
            <span className="steps-marker"></span>
            <div className="steps-content">
              <p className="is-size-6">{step}</p>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export { Wizard };

import React from 'react';
import { Labels } from '../utils/Labels';

type ComponentProps = {
  isActive: boolean;
  title?: string;
  onOkClick?: Function;
  onCancelClick: Function;
  hasOnlyClose?: boolean;
};

const Alert = (props: React.PropsWithChildren<ComponentProps>): React.ReactElement => {
  return (
    <div className={`modal ${props.isActive && 'is-active'}`}>
      <div className="modal-background">{props.title}</div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title"></p>
        </header>
        <section className="modal-card-body">{props.children}</section>
        <footer className="modal-card-foot" style={{ justifyContent: 'center' }}>
          {props.hasOnlyClose && (
            <button className="button" onClick={() => props.onCancelClick()}>
              {Labels.t('alert.close')}
            </button>
          )}

          {!props.hasOnlyClose && (
            <>
              <button className="button" onClick={() => props.onCancelClick()}>
                {Labels.t('alert.no')}
              </button>
              <button className="button" onClick={() => props.onOkClick && props.onOkClick()}>
                {Labels.t('alert.yes')}
              </button>
            </>
          )}
        </footer>
      </div>
    </div>
  );
};

export { Alert };

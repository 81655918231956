import React from 'react';

type ComponentProps = {};

const Home = (props: React.PropsWithChildren<ComponentProps>): React.ReactElement => {
  return (
    <React.Fragment>
      <div>Home</div>
    </React.Fragment>
  );
};

export { Home };

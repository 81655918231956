import React, { useEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import * as QueryString from 'query-string';
import { useRegisterStore, usePaymentStore, useSessionStore } from '../store';
import { WizardContainer } from '../components';
import { OrderResult } from '../models';
import { Labels } from '../utils/Labels';

type ComponentProps = {};

const Confirmation = (props: React.PropsWithChildren<ComponentProps>): React.ReactElement => {
  const currentStep = 4;
  const history = useHistory();
  const { id } = useParams();
  const { referrer } = useSessionStore();
  const { clear } = useRegisterStore();
  const { isChecking, checkPayment, payment, hasFatalError } = usePaymentStore();
  const { state, search } = useLocation<OrderResult>();

  const model: OrderResult = {
    orderNumber: 0,
    amount: 0,
    date: '',
    hasRedirect: false,
    redirectUrl: '',
    hasError: false,
  };

  if (state) {
    model.orderNumber = state.orderNumber;
    model.amount = state.amount;
    model.date = state.date;
  }

  if (payment) {
    model.hasError = payment.hasError;
    model.orderNumber = payment.orderNumber;
    model.amount = payment.amount;
    model.date = payment.date;
  }

  useEffect(() => {
    const params = QueryString.parse(search);
    if (!state && params.orderId) {
      checkPayment(params.orderId.toString());
    } else {
      if (clear) {
        clear();
      }
    }
  }, []);

  useEffect(() => {
    if (!isChecking && !payment?.hasError && clear) {
      clear();
    }
  }, [isChecking]);

  useEffect(() => {
    const wnd = window as any;

    if (localStorage.getItem('orderNumber') === model.orderNumber.toString()) return;

    wnd.dataLayer = wnd.dataLayer || [];
    wnd.dataLayer.push({
      event: 'transaction',
      transactionId: model.orderNumber,
      transactionTotal: model.amount,
    });

    localStorage.setItem('orderNumber', model.orderNumber.toString());
  }, []);

  if (hasFatalError) {
    return (
      <WizardContainer stepIndex={currentStep} hideDismissButton={true}>
        <div className="column is-two-thirds box-center has-text-centered">
          <span className="icon has-text-danger" style={{ margin: '2rem 0' }}>
            <i className="far fa-3x fa-times-circle"></i>
          </span>
          <h3 className="subtitle is-3">{Labels.t('confirmation.error.1')} </h3>
          <h3 className="subtitle is-3">{Labels.t('confirmation.error.2')}</h3>
          <h3 className="subtitle is-3">{Labels.t('confirmation.error.3')}</h3>

          <hr />
          <div className="field is-grouped is-grouped-centered">
            <button className="button is-light" type="button" onClick={() => (window.location.href = referrer)}>
              {Labels.t('confirmation.back')}
            </button>
          </div>
        </div>
      </WizardContainer>
    );
  }

  if (!state && isChecking) {
    return (
      <WizardContainer stepIndex={currentStep} hideDismissButton={true}>
        <div className="column is-two-thirds box-center has-text-centered">
          <div className="element is-loading"></div>
        </div>
      </WizardContainer>
    );
  }

  if (!state && payment?.hasError) {
    return (
      <WizardContainer stepIndex={currentStep} hideDismissButton={true}>
        <div className="column is-two-thirds box-center has-text-centered">
          <span className="icon has-text-danger" style={{ margin: '2rem 0' }}>
            <i className="far fa-3x fa-times-circle"></i>
          </span>
          <h3 className="subtitle is-3">{Labels.t('confirmation.payment.error')}</h3>
          <hr />
          <div className="field is-grouped is-grouped-centered">
            <button className="button is-light" type="button" onClick={() => history.replace(`/events/${id}/payment`)}>
              {Labels.t('confirmation.payment.retry')}
            </button>
          </div>
          <div className="field is-grouped is-grouped-centered">
            <button className="button is-light" type="button" onClick={() => (window.location.href = referrer)}>
              {Labels.t('confirmation.back')}
            </button>
          </div>
        </div>
      </WizardContainer>
    );
  }

  const formatPrice = (price: number): string => {
    if (!price) return '-';
    return price.toString().replace('.', ',');
  };

  return (
    <WizardContainer stepIndex={currentStep} hideDismissButton={true}>
      <div className="column is-two-thirds box-center has-text-centered">
        <span className="icon has-text-success" style={{ margin: '2rem 0' }}>
          <i className="far fa-3x fa-check-circle"></i>
        </span>

        {model.orderNumber && (
          <>
            <h3 className="subtitle is-3">
              {Labels.t('confirmation.success.1')} {model.orderNumber}
            </h3>
            <h5 className="subtitle is-5">
              {Labels.t('confirmation.success.2')}: {formatPrice(model.amount)} Lei
            </h5>
            <h5 className="subtitle is-5">
              {Labels.t('confirmation.success.3')}: {model.date}
            </h5>
          </>
        )}

        {!model.orderNumber && (
          <>
            <h3 className="subtitle is-3"> {Labels.t('confirmation.success.info')}</h3>
            <h5 className="subtitle is-5"> {Labels.t('confirmation.success.email')}</h5>
          </>
        )}

        <hr />
        <div className="field is-grouped is-grouped-centered">
          <button className="button is-light" type="button" onClick={() => (window.location.href = referrer)}>
            {Labels.t('confirmation.back')}
          </button>
        </div>
      </div>
    </WizardContainer>
  );
};

export { Confirmation };

import { Language, SessionState } from '../models';

class LocalStorageService {
  static saveState(state: SessionState): void {
    try {
      const jsonState = JSON.stringify(state);
      localStorage.setItem('session', jsonState);
    } catch (error) {
      console.error(error);
    }
  }

  static getSessionState(): SessionState {
    let state: SessionState = {
      referrer: '',
      payReferrer: '',
      language: Language.RO,
    };

    try {
      const stringSessionState = localStorage.getItem('session');
      if (stringSessionState) {
        const localState = JSON.parse(stringSessionState) as SessionState;
        if (!localState || localState != null) {
          state = localState;
          if (!localState.language) state.language = Language.RO;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return state;
  }
}

export { LocalStorageService };

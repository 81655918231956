import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useRegisterStore } from '../store';
import { WizardContainer, SelectButton } from '../components';
import { RegisterState, PaymentType } from '../models';
import { Labels } from '../utils/Labels';

const Payment = (): React.ReactElement => {
  const currentStep = 2;
  const history = useHistory();
  const { id } = useParams();

  const paymentType = useRegisterStore((state: RegisterState) => state.paymentType);
  const setPaymentType = useRegisterStore((state: RegisterState) => state.setPaymentType);

  const goNext = (): void => {
    history.push(`/events/${id}/summary`);
  };

  if (!setPaymentType) return <></>;

  return (
    <WizardContainer stepIndex={currentStep}>
      <div className="column is-half box-center">
        <h2 className="subtitle">{Labels.t('payment.type')}</h2>
        <hr />

        <SelectButton isActive={paymentType === PaymentType.CC} onClick={() => setPaymentType(PaymentType.CC)}>
          <div className="level">
            <span>{Labels.t('payment.cc')}</span>
            <img style={{ marginLeft: 4 }} src="/assets/cards.jpg" />
          </div>
        </SelectButton>

        <SelectButton
          isActive={paymentType === PaymentType.OP}
          style={{ marginTop: '0.5rem' }}
          onClick={() => setPaymentType(PaymentType.OP)}
        >
          <span>{Labels.t('payment.op')}</span>
          <span className="text-subtitle">{Labels.t('payment.info')}</span>
        </SelectButton>

        <hr />
        <div className="field is-grouped is-grouped-centered">
          <p className="control">
            <button className="button is-light" type="button" onClick={() => history.replace(`/events/${id}/billing`)}>
              {Labels.t('form.previous')}
            </button>
          </p>
          <p className="control">
            <button className="button is-primary" type="button" onClick={() => goNext()}>
              {Labels.t('form.next')}
            </button>
          </p>
        </div>
      </div>
    </WizardContainer>
  );
};

export { Payment };
